<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">User</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of User
            <span v-if="users" class="tableTotal font-15 poppins"
              >(Total : {{ users.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div></div>
            <div class="float-right ml-4">
              <router-link to="/createUser" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-2">Name</th>

                      <th class="col-md-3">Email</th>

                      <th class="col-md-2">Mobile</th>

                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in users" v-bind:key="item._id">
                      <td class="text-center">{{ index + 1 }}</td>

                      <td>
                        <div>{{ item.name }}</div>
                      </td>

                      <td>
                        <div>{{ item.email }}</div>
                      </td>

                      <td>
                        <div>{{ item.mobile }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editUser',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <!-- <div class="deletedModal mr-8">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteUserById(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div> -->
                          <div class="mr-8">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                          <div class="mr-8">
                            <div class="mt-2 mb-2" v-if="loader === index" align="center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </div>
                            <v-tooltip bottom v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="white"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="exportUserDataById(item._id, index)"
                                >
                                  <b-icon-download color="black"></b-icon-download>
                                </v-btn>
                              </template>
                              <span>Export</span>
                            </v-tooltip>
                          </div>
                          <!-- <div>
                            <v-btn
                              depressed
                              color="primary"
                              @click="resetPassword(item._id)"
                            >
                              Reset password
                            </v-btn>
                          </div> -->
                          <div class="mr-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="blue"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="resetPassword(item._id)"
                                >
                                  <img
                                    src="@/assets/Images/resetting-white.svg"
                                    alt="logo"
                                    width="15px"
                                  />
                                </v-btn>
                              </template>
                              <span>Reset password</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="users.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="users">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllUsers(page)"
      />
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <form name="details" class="formPart text-left" @submit.prevent="submitForm(id)">
        <v-card>
          <v-card-title>
            <span class="text-h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    name="password"
                    type="password"
                    :rules="passwordRules"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    :rules="confirmPasswordRules"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import customerService from '../../services/customerService';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      users: [
        {
          name: 'User 1',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
        {
          name: 'User 2',
          email: 'admin.palettier@palettier.com',
          mobile: '0987654321',
        },
      ],
      page: 1,

      totalCount: 0,

      totalPages: '',
      dialog: false,
      items: ['Cart', 'Orders', 'sizes', 'Addresses', 'Wishlist', 'Saved Looks', 'Stylist Assists'],

      password: '',
      confirmPassword: '',
      passwordRules: [
        (value) => !!value || 'Please type password.',
        (value) => (value && value.length >= 6) || 'minimum 6 characters',
      ],
      confirmPasswordRules: [
        (value) => !!value || 'type confirm password',
        (value) => value === this.password || 'The password confirmation does not match.',
      ],
      user: '',
      loader: '',
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    async exportUserDataById(id, index) {
      this.loader = index;

      const data = {};
      const vendorID = JSON.parse(localStorage.getItem('selectedVendor'));
      data.vendorId = vendorID;
      data.userId = id;
      const result = await customerService.exportUserData(data);

      if (result.status === 200) {
        window.open(result.data);
        this.$store.commit('setSnackbar', {
          content: 'User Data Exported Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      this.loader = '';
    },
    async getAllUsers() {
      const data = {};
      data.page = this.page;
      data.vendor = JSON.parse(localStorage.getItem('selectedVendor'));
      const result = await customerService.getAllCustomers(data);
      this.users = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      //
      //console.log('result', this.users);
    },
    async deleteUserById(id) {
      const result = await customerService.deleteUser(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: ' User Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }

      console.log('result', this.users);
    },
    async submitForm() {
      if (this.password == '') {
        await this.$store.commit('setSnackbar', {
          content: 'The password should not be empty!',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
        return;
      } else if (this.password != this.confirmPassword) {
        await this.$store.commit('setSnackbar', {
          content: "The password fields don't match!",
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
        return;
      } else {
        const data = {};
        data._id = this.user;
        data.password = this.confirmPassword;
        const result = await customerService.resetPassword(data);
        if (result.status === 200) {
          await this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.dialog = false;
          this.$router.push('/viewUsers');
        } else {
          await this.$store.commit('setSnackbar', {
            content: "The password fields don't match!",
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
      console.log(result);
    },
    resetPassword(id) {
      this.dialog = true;
      this.user = id;
      this.$router.push('/viewUsers');
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteUserById(id);
        console.log('got confirmation');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
